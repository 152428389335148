/**
 * Adjust the body header class to match the current page position.
 */
function _header_scroll() {
	if ($(window).scrollTop() <= 0 && headerScroll) {
		$('.header').addClass('at-top');
		$('body').addClass('at-top');
	} else {
		$('.header').removeClass('at-top');
		$('body').removeClass('at-top');
	}
}

$(document).ready(_header_scroll);
$(document).scroll(_header_scroll);

$(document).on('opened.zf.offcanvas', function() {
	$('.mobile-toggle').addClass('is-active');
});

$(document).on('closed.zf.offcanvas', function() {
	$('.mobile-toggle').removeClass('is-active');
});
